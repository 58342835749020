<template>
  <div class="demo-tabs">
    <span class="custom-tabs-label summary-app-head">
      <el-avatar :src="logo_img"></el-avatar>
      <el-row class="flex-row">
        <span class="app-name">{{ appName }}</span>
        <span class="app-version">{{ appVersion }}</span>
      </el-row>
    </span>
    <dp-summary class="dp-s" :showImages="showImages" :downloadLink="downloadLink">
      <div class="app-desc">
        <div class="_bg-taichi"></div>
        <h5 v-for="index in summary.length" :key="summary[index]">{{ summary[index - 1] }}</h5>
        <div class="desccc" style="
    border-top: 1px solid;
    font-family: cursive;
    font-size: 20px;
    font-weight: unset;
    text-align:left;
    line-height: 1.8em;
    max-height: 140px;
    overflow: auto;">
          <p>
            青乌相地是一款日历，黄历，吉凶，罗盘，易学，古籍为一体的应用；
          </p>
          <p>可以帮助用户更好地了解传统农历和相关的文化知识，<br/>
            同时提供一些日常生活中实用的指导和娱乐功能。<br/>
            易学术数专业排盘起局软件，包含八字排盘、梅花易数排盘、及电子罗盘等工具，界面精美，内容丰富，运行流畅，希望能成为易学爱好者的好帮手。<br/>
            <br/>
            <text style="font-size: small;opacity: 0.4;font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">更多功能正在开发中。</text>
      
          </p>
          <p
            style="font-size: small;opacity: 0.4;font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">
            应用中部分功能仅供娱乐测试和参考，并无科学实证。
          </p>
        </div>
        <div class="clound-img">
          <img src="@/assets/clound.png" alt="">
        </div>
      </div>
    </dp-summary>

    <!-- <dp-some-help :helpList="helplist"></dp-some-help> -->

  </div>

  <footer-icp icp="肆拾伍零壹零伍零贰零零零柒柒贰零">
  </footer-icp>
  <CharactersBg />
</template>

<script>
import DpSummary from './components/DpSummary.vue';
// import DpSomeHelp from './components/DpSomeHelp.vue';
import FooterIcp from './components/FooterIcp.vue';
import CharactersBg from './components/CharactersBg.vue';
import logo_img from '@/assets/logo.png';
// import { Calendar } from '@element-plus/icons-vue'
export default {
  name: 'App',
  data() {
    return {
      activeName: "first",
      appName: "青乌相地",
      slogan: "",
      appVersion: "1.4.0",
      downloadLink: "https://zzerx.cn/download/qwxd-new.apk",
      summary: ["观时辰，识人命，贵宜忌，卜吉凶。",
        // "千年智慧融今朝，预知天命顺所求。",
        // "指南针，指乾坤，妙法交融，助尔行方。"
      ],
      mainSummary: "",
      logo_img,
    }
  },
  components: {
    // Calendar,
    DpSummary,
    CharactersBg,
    FooterIcp
  },
  methods: {

  }
}


</script>

<style lang="scss">
@font-face {
  font-family: ltqcc;
  src: url('@/assets/fonts/ltqcc.ttf');
}


body,
html {
  margin: 0;
  padding: 0;
}

body {
  background-color: rgb(0, 54, 54);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* padding-top: 20px; */
  /* padding-left: 5%;
  padding-right: 5%; */
  /* background-image: linear-gradient(to left, transparent, rgb(0, 54, 54)), url('@/assets/bg2.webp'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  /* height: 100vh; */
  width: 100vw;
  overflow: hidden;
}

.dp-summary {
  margin-top: 8vh;
}

.dp-summary h5 {
  margin: 0.2em;
}

.summary-app-head {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
}

.summary-app-head a {
  margin: 0 0.5em;
}

.demo-tabs {

  padding: 32px;
  color: #6b778c;
  font-size: 20px;
  font-weight: 600;
}

.app-name {
  font-family: ltqcc;
  color: aqua;
  margin: 0 10px;
  font-size: larger;
}

.app-version {
  font-size: 10px !important;
  font-weight: lighter;
  font-family: Arial, Helvetica, sans-serif;
  color: aqua;
  background-color: darkslategrey;
  padding: 2px 6px;
  border-radius: 2px;
}

.app-desc {
  font-family: ltqcc;
  color: rgb(239, 255, 255);
  font-size: 30px;
  background-color: rgba(0, 44, 44, 0.89);
  width: fit-content;
  margin: auto;
  padding: 50px;
  font-weight: lighter;
  position: relative;
  user-select: none;

  ._bg-taichi {
    width: 100px;
    height: 100px;
    left: 0;
    top: 0;
    position: absolute;
    overflow: hidden;

    &::after {
      position: absolute;
      content: "☯";
      left: -30px;
      top: -50px;
      color: #00e3f327;
      font-size: 100px;
    }
  }

}

.clound-img {
  width: 30px;
  height: 40px;
  position: absolute;
  left: 0px;
  top: 84%;
  /* background-image: url('@/assets/clound.png'); */
  filter: drop-shadow(2px 2px 15px rgba(0, 255, 255, 0.575));
  transition: 0.5s;
  animation: cloudMovement 30s infinite;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@keyframes cloudMovement {
  0% {
    // top: 50%;
    left: 0px;
  }

  50% {
    // top: 54%;
    left: 50px;
  }

  100% {
    // top: 50%;
    left: 0px;
  }
}

@media screen and (max-width: 600px) {
  .summary-app-head {
    z-index: 999;
    position: fixed;
    left: 0;
    padding: 10px;
    top: 0px;
    width: 100%;
    background-color: rgba(0, 32, 32, 0.726);
  }

  .app-desc {
    padding: 20px;
    font-size: 20px;
  }

  .desccc{
    font-size: 17px !important;
  }

  .clound-img {
    left: 0;
    width: 400px;

    // height: 400px; 
    img {
      width: 100%;
      // height: 100%;
    }
  }

}

/* 定义滚动条的样式 */
::-webkit-scrollbar {
  width: 8px; /* 滚动条宽度 */
}

/* 定义滚动条轨道的样式 */
::-webkit-scrollbar-track {
  background: #f1f1f128; /* 滚动条轨道背景颜色 */
}

/* 定义滚动条滑块（thumb）的样式 */
::-webkit-scrollbar-thumb {
  background: #88888857; /* 滑块背景颜色 */
  // border-radius: 6px; /* 滑块圆角 */
}

/* 定义鼠标悬停在滑块上时的样式 */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* 鼠标悬停时的滑块背景颜色 */
}

</style>
