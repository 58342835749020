<template>
  <div class="dp-summary">
    <div class="summary-text">
      <slot></slot>

      <!-- <div class="download-button" size="large" type="primary">
        <a :href="downloadLink">
          立即下载
        </a>
      </div> -->


      <el-row class="summary-link">
        <div class="list-btn" @click="share">
          分享
        </div>
        <div class="list-btn" @click="toDownload">
          应用下载
        </div>
        <div class="list-btn">
          讨论
        </div>
      </el-row>


    </div>
    <div class="summary-image">
      <el-image :src="pewviewImg"></el-image>
      <!-- <el-carousel :interval="200000" type="card">
        <el-carousel-item class="carousel-img" v-for="item in showImages" :key="item">
          <h3 justify="center">{{ item.alt }}</h3>
          <el-image :src="item.src"></el-image>
        </el-carousel-item>
      </el-carousel> -->
    </div>
    <input style="display: none;" type="text" id="copytext" :value="shareText">
    <DownloadAlret ref="refDownloadAlret"/>
  
  </div>
</template>

<script>
import pewviewImg from '@/assets/app-preview/previews.png';
import DownloadAlret from '@/components/DownloadAlret.vue';

export default {
  components: {
    DownloadAlret,
  },
  props: {
    downloadLink: { type: String },
    link: { type: Array },
    showImages: { type: Object },
  },
  data() {
    return {
      pewviewImg,
      shareText: '青乌相地 |  | 日历 - 八字 - 宜忌 - 卜卦 - 罗盘 http://192.168.1.4:8080/'
    }
  },
  computed: {
  },
  methods: {
    toDownload() {
      this.$refs.refDownloadAlret.open();
    },
    share() {
      this.copyText();
    },
    copyText() {
      const copyText = document.getElementById("copytext");
      if(!navigator.clipboard?.writeText) return alert("复制失败: ");
      navigator.clipboard.writeText(copyText.value)
        .then(function () {
          alert("已复制到剪贴板: " + copyText.value);
        })
        .catch(function (error) {
          alert("复制失败: " + error);
        });
    }
  }
}
</script>

<style >
/* .el-carousel__item .is-active .is-in-stage .el-carousel__item--card .carousel-img {
  position: fixed;
} */

.download-button {
  font-size: 1.1em;
  /* font-weight: bold; */
}


.dp-summary {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  text-decoration: dashed;
}

.download-button a {
  text-decoration: dashed;
  color: aliceblue;
}

/* 走马灯外部 */
.dp-summary .el-carousel__container {
  height: 60vh;
}

/* 走马灯内部图片 */
.dp-summary .el-image__inner {
  /* width: 32vh; */
}

.dp-summary .el-carousel__mask {
  background-color: rgba(0, 212, 212, 0.801);
}

.el-carousel__container {
  background-color: rgba(0, 115, 119, 0.616);
  border-bottom: 2px solid aqua;
  /* box-shadow: 1px 1px 10px aqua; */
  background-image: url('@/assets/xianhe.png');
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: right top -30%;
}

.summary-text {
  width: 60%;
  flex: 1;
}

.summary-image {
  /* height: 600px; */
  /* margin: 5%; */
  min-width: 40%;
  background-color: rgba(0, 115, 119, 0.616);
  border-bottom: 2px solid aqua;
  max-width: 50vw;
  flex: auto;
}

.summary-link {
  font-family: ltqcc;
  margin: 1.5em;
  display: flex;
  justify-content: center;
  font-size: 25px;
  /* align-items: center; */
}

.summary-link .list-btn {
  user-select: none;
  width: 1.5em;
  background-color: rgba(0, 255, 255, 0.699);
  color: rgb(0, 43, 43);
  margin: 2px 6px;
  padding: 30px 6px;
  text-justify: inter-word;
  text-align: center;
  position: relative;
  border: 6px double rgb(0, 104, 104);
  top: 0;
  transition: 0.5s;
  cursor: pointer;
}

.summary-link .list-btn:hover {
  top: -10px;
  box-shadow: 1px -1px 10px rgba(0, 0, 0, 0.699);
}




.summary-goto-help {
  position: relative;
  height: auto;
  color: silver;
  font-size: 5em;
  top: 0.3em;
}




@media screen and (max-width: 600px) {

  .dp-summary {
    /* width: 100%; */
  }

  .summary-image {
    max-width: 90vw;
    height: max-content;
  }

  /* 走马灯外部 */
  /* .dp-summary .el-carousel__container {
    height: 300px;
  }

  .dp-summary .el-image__inner {
    width: 100%;
  } */


}
</style>