<template>
  <el-dialog custom-class="download-show-dialog" width="fit-content" v-model="dialogTableVisible" @open="onOpen">
    <div class="download">
      <div class="download-btn-group">

        <a class="download-btn" target="_blank" href="https://qwxd.zzerx.cn/download/qwxd-new.apk">
          <img class="apple" :src="apkImg" alt="">
        </a>
        <a class="download-btn disabled" >
          <!-- onclick="alert('开发中，敬请请等待');" -->
          <img class="apple" :src="appleImg" alt="">
        </a>
        <a class="download-btn disabled" target="_blank" >
          <!-- onclick="alert('请先使用直接下载APK方式');" -->
          <img class="android" :src="androidImg" alt="">
        </a>
      </div>
      <div class="download-qrcode">
        <img :src="mpweixinImg">
        <span>微信小程序搜索：青乌相地</span>
        <!-- <a >打开微信小程序</a> -->
      </div>
    </div>
  </el-dialog>
</template>

<script>
import appleImg from '@/assets/available-on-the-app-store.svg';
import androidImg from '@/assets/get-it-on-google-play.svg';
import mpweixinImg from '@/assets/mp_weixin.png';
import apkImg from '@/assets/apk-download.svg';

export default {
  props: {
  },
  data() {
    return {
      dialogTableVisible: false,
      appleImg,
      androidImg,
      mpweixinImg,
      apkImg
    }
  },
  methods: {
    open() {

      this.dialogTableVisible = true;
      console.log("Open", this.dialogTableVisible);
    },
    onOpen(e) {
      console.log("onOpen", e);
      // wx.config({
      //   debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
      //   appId: '', // 必填，公众号的唯一标识
      //   timestamp: new Date().getTime(), // 必填，生成签名的时间戳
      //   nonceStr: '', // 必填，生成签名的随机串
      //   signature: '',// 必填，签名
      //   jsApiList: [], // 必填，需要使用的JS接口列表
      //   openTagList: [] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
      // });
    }
  }
}
</script>

<style lang="scss">
// .download-show-dialog {
// background-color: darkcyan !important;

// .el-dialog__headerbtn .el-dialog__close {
//   color: white;
//   font-weight: bold;
// }
// }

.download {
  display: flex;

  .download-btn-group {
    margin: auto;

    .download-btn {
      background-color: white;
      display: flex;
      border: 1px solid lightgray;
      width: fit-content;
      margin: 10px;
      border-radius: 4px;
      padding: 6px;
      cursor: pointer;

      &:hover {
        box-shadow: 1px 1px 4px lightgray;
      }

      &.disabled {
        filter: grayscale(2);
        opacity: 0.5;
      }

      .apple {
        width: 160px;
        height: 60px;
      }

      .android {
        width: 160px;
        height: 60px;
      }

    }
  }

  .download-qrcode {
    display: flex;
    flex-direction: column;
    margin: 20px;
    justify-content: center;
    align-items: center;
    border-left: 1px solid lightgray;
    padding: 20px;

    span {
      margin-top: 16px;
      text-align: center;
    }

    img {
      width: 120px;
      // border-radius: 50%;
    }
  }
}

@media screen and (max-width: 600px) {
  .download {
    width: 70vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .download-qrcode {
      border-left: none;
      // padding-left: 0px;
    }
  }

}
</style>